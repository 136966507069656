let player;

const onYouTubeIframeAPIReady = () => {
	player = new YT.Player('player', {
		playerVars : {
			theme    : "light",
			color    : "white",
			showinfo : 0
		}
	});
};


$(function() {

	'use strict';

	window.sr = ScrollReveal();
	sr.reveal('.pattern');

	const w = $(window).width();
	const x = 768;

	// ウィンドウサイズによってnavを設置
	const settingWideNav = () => {
		let template;
		let compiled;
		if (w > x) {
			template = $("#wideNavTemplate").text();
			compiled = _.template(template);
			$('#navArea').empty().append(compiled);
		} else {
			template = $("#mobileNavTemplate").text();
			compiled = _.template(template);
			$('#navArea').empty().append(compiled);
		}
	}

	settingWideNav();


	const setAboutBtnArea = () => {
		let template;
		let compiled;
		if (w > x) {
			template = $("#aboutBtnAreaTemplate").text();
			compiled = _.template(template);
			$('#aboutBtnArea').empty().append(compiled);
		} else {
			template = $("#aboutBtnAreaMobileTemplate").text();
			compiled = _.template(template);
			$('#aboutBtnArea').empty().append(compiled);
		}
	}

	setAboutBtnArea();

	//スクロールでトップに戻るアイコン表示
	$(window).scroll( () => {
		if ( $(window).scrollTop() > 100 ) {
			$('#backToTop').show().removeClass('show_effect').addClass('show_effect');;
		} else {
			$('#backToTop').hide().removeClass('show_effect');
		}
	});

	//トップに戻る
	$('#backToTop').on('click', () => {
		$('body,html').animate({
			scrollTop: 0
		}, 500);
	});

	//キー移動
	$('#keySelect a').on('click', function() {
		const speed  = 400;
		const $elm   = $(this);
		const href   = $(this).attr("href");
		const target = $(href == "#" || href == "" ? 'html' : href);
		let position;

		if (w > x) {
			position = target.offset().top - 70;
		} else {
			position = target.offset().top - 160;
		}

		$(this).addClass('active');

		$('body,html').animate({
			scrollTop: position
		}, speed, 'swing', function(){
			$elm.removeClass('active');
		});

		return false;
	});


	let roman_num; // 変数roman_numを定義

	// ============ コード進行のカウント ============
	const cadence_num = $('.cadence').length;
	$('#cadenceNum').text(cadence_num);

	// ============ シェアボタン ============
	$('#twitterShareBtn').attr('href','http://twitter.com/share?url=http://chords-map.net/&amp;text=コード進行パターン表 現在'+cadence_num+'パターン&via=chords_map');


	// ============ コード進行パターン表について ============
	// 表示処理
	$('#aboutBtn').on('click', () => {
		$('#mainView, #aboutBtn, #backAboutBtn').hide().removeClass('show_effect');
		$('#aboutView, #backAboutBtn').show().removeClass('show_effect').addClass('show_effect');
		$('button[type="submit"],a[type="button"]').addClass('disabled');
	});
	// 非表示処理
	$('#backAboutBtn').on('click', () => {
		$('#mainView, #aboutBtn').show().removeClass('show_effect').addClass('show_effect');
		$('#aboutView, #backAboutBtn').hide().removeClass('show_effect');
		$('button[type="submit"],a[type="button"]').removeClass('disabled');
	});


	// メジャー or マイナーを選択（GO前）
	$('[name=tonality]').on('change', () => {
		const val_tonality = $('[name=tonality]').val(); // メジャー or マイナー
		let template = '';
		let compiled = '';
		if (val_tonality === 'major') {
			// メジャーが選択されたとき
			template = $("#majorKeyListTemplate").text();
			compiled = _.template(template);
			$('[name=chord]').empty().append(compiled);
		} else {
			// マイナーが選択されたとき
			template = $("#minorKeyListTemplate").text();
			compiled = _.template(template);
			$('[name=chord]').empty().append(compiled);
		}
	});

	// 使用楽曲モーダルウィンドウ
	$('.music_modal_icon').on('click', function() {
		const video_id  = $(this).attr('data-video-id');
		const url       = $(this).parent('.music_btn').parent('.music_detail').next('.music_thumb').find('a').attr('href');
		const blog_page = url.match(/\d+/);
		const title     = $(this).parent('.music_btn').prev('.music_title').text();
		// はてなカード用URL
		const ht_url = '//hatenablog-parts.com/embed?url=http%3A%2F%2Fpast-orange.com/po_sp/?p=';

		$('#musicModalBlogTitle').empty().text(title);
		$('#modalInlineFrame')
			.empty()
			.attr({
				title: title,
				src  : ht_url + blog_page
			});

		if ( w > 500 ) {
			// console.log(video_id);
			// console.log(title);
			// console.log(url);
			// console.log(blog_page[0]);
			$('#player').show();
			$('#modalMobilePlayer').hide();
			player.loadVideoById(video_id);
		} else {
			$('#player').hide();
			$('#modalMobilePlayer').show();
			const src = 'https://www.youtube.com/embed/' + video_id;
			// console.log(src);
			$('#modalMobilePlayer').attr("src", src);
		}
	});

	$('select[name="chord"]').on('click', () => {
		$('.cadence span').removeClass('changed');
	});

	// 初期化
	const defalutNum = () => {
		$('.rm_1').text('Ⅰ');
		$('.rm_2').text('Ⅱ');
		$('.rm_3').text('Ⅲ');
		$('.rm_4').text('Ⅳ');
		$('.rm_5').text('Ⅴ');
		$('.rm_6').text('Ⅵ');
		$('.rm_7').text('Ⅶ');
		$('.rm_f1').text('♭Ⅰ');
		$('.rm_f2').text('♭Ⅱ');
		$('.rm_f3').text('♭Ⅲ');
		$('.rm_f4').text('♭Ⅳ');
		$('.rm_f5').text('♭Ⅴ');
		$('.rm_f6').text('♭Ⅵ');
		$('.rm_f7').text('♭Ⅶ');
		$('.rm_s1').text('#Ⅰ');
		$('.rm_s2').text('#Ⅱ');
		$('.rm_s3').text('#Ⅲ');
		$('.rm_s4').text('#Ⅳ');
		$('.rm_s5').text('#Ⅴ');
		$('.rm_s6').text('#Ⅵ');
		$('.rm_s7').text('#Ⅶ');
	}


	// ============ セレクタ選択後の処理 ============
	$('#selectForm').on('submit', () => {

		$('.cadence span').addClass('changed');

		sr.reveal('.pattern');

		// セレクトボックスの値を取得
		const val_tonality = $('[name=tonality]').val(); // メジャーorマイナー
		const val_chord    = $('[name=chord]').val(); // キー

		// ************ メジャーを選択した場合 ************
		if ( val_tonality === 'major' ) {
			$('#majorSection').show().removeClass('show_effect').addClass('show_effect'); // メジャーを表示
			$('#minorSection').hide().removeClass('show_effect'); // マイナーを非表示

			$('#key_1').attr('href','#major_1');
			$('#key_2').attr('href','#major_2');
			$('#key_3').attr('href','#major_3');
			$('#key_4').attr('href','#major_4');
			$('#key_5').attr('href','#major_5');
			$('#key_6').attr('href','#major_6');
			$('#key_7').attr('href','#major_7');


			// 選択したキーによってスイッチ
			switch (val_chord){

				case 'Degree':
					defalutNum(); //初期化
					break;

				case 'C':
					$('.rm_1').text('C');
					$('.rm_2').text('D');
					$('.rm_3').text('E');
					$('.rm_4').text('F');
					$('.rm_5').text('G');
					$('.rm_6').text('A');
					$('.rm_7').text('B');
					$('.rm_f1').text('C♭');
					$('.rm_f2').text('D♭');
					$('.rm_f3').text('E♭');
					$('.rm_f4').text('F♭');
					$('.rm_f5').text('G♭');
					$('.rm_f6').text('A♭');
					$('.rm_f7').text('B♭');
					$('.rm_s1').text('C#');
					$('.rm_s2').text('D#');
					$('.rm_s3').text('E#');
					$('.rm_s4').text('F#');
					$('.rm_s5').text('G#');
					$('.rm_s6').text('A#');
					$('.rm_s7').text('B#');
					break;

				case 'G':
					$('.rm_1').text('G');
					$('.rm_2').text('A');
					$('.rm_3').text('B');
					$('.rm_4').text('C');
					$('.rm_5').text('D');
					$('.rm_6').text('E');
					$('.rm_7').text('F#');
					$('.rm_f1').text('G♭');
					$('.rm_f2').text('A♭');
					$('.rm_f3').text('B♭');
					$('.rm_f4').text('C♭');
					$('.rm_f5').text('D♭');
					$('.rm_f6').text('E♭');
					$('.rm_f7').text('F');
					$('.rm_s1').text('G#');
					$('.rm_s2').text('A#');
					$('.rm_s3').text('C');
					$('.rm_s4').text('C#');
					$('.rm_s5').text('D#');
					$('.rm_s6').text('F');
					$('.rm_s7').text('G');
					break;

			   case 'D':
					$('.rm_1').text('D');
					$('.rm_2').text('E');
					$('.rm_3').text('F#');
					$('.rm_4').text('G');
					$('.rm_5').text('A');
					$('.rm_6').text('B');
					$('.rm_7').text('C#');
					$('.rm_f1').text('D♭');
					$('.rm_f2').text('E♭');
					$('.rm_f3').text('F');
					$('.rm_f4').text('G♭');
					$('.rm_f5').text('A♭');
					$('.rm_f6').text('B♭');
					$('.rm_f7').text('C');
					$('.rm_s1').text('D#');
					$('.rm_s2').text('F');
					$('.rm_s3').text('G');
					$('.rm_s4').text('G#');
					$('.rm_s5').text('A#');
					$('.rm_s6').text('C');
					$('.rm_s7').text('D');
					break;

			   case 'A':
					$('.rm_1').text('A');
					$('.rm_2').text('B');
					$('.rm_3').text('C#');
					$('.rm_4').text('D');
					$('.rm_5').text('E');
					$('.rm_6').text('F#');
					$('.rm_7').text('G#');
					$('.rm_f1').text('A♭');
					$('.rm_f2').text('B♭');
					$('.rm_f3').text('C');
					$('.rm_f4').text('D♭');
					$('.rm_f5').text('E♭');
					$('.rm_f6').text('F');
					$('.rm_f7').text('G');
					$('.rm_s1').text('A#');
					$('.rm_s2').text('B#');
					$('.rm_s3').text('D');
					$('.rm_s4').text('D#');
					$('.rm_s5').text('E#');
					$('.rm_s6').text('G');
					$('.rm_s7').text('A');
					break;

			   case 'E':
					$('.rm_1').text('E');
					$('.rm_2').text('F#');
					$('.rm_3').text('G#');
					$('.rm_4').text('A');
					$('.rm_5').text('B');
					$('.rm_6').text('C#');
					$('.rm_7').text('D#');
					$('.rm_f1').text('E♭');
					$('.rm_f2').text('F');
					$('.rm_f3').text('G');
					$('.rm_f4').text('A♭');
					$('.rm_f5').text('B♭');
					$('.rm_f6').text('C');
					$('.rm_f7').text('D');
					$('.rm_s1').text('E#');
					$('.rm_s2').text('G');
					$('.rm_s3').text('A');
					$('.rm_s4').text('A#');
					$('.rm_s5').text('B#');
					$('.rm_s6').text('D');
					$('.rm_s7').text('E');
					break;

			   case 'B':
					$('.rm_1').text('B');
					$('.rm_2').text('C#');
					$('.rm_3').text('D#');
					$('.rm_4').text('E');
					$('.rm_5').text('F#');
					$('.rm_6').text('G#');
					$('.rm_7').text('A#');
					$('.rm_f1').text('B♭');
					$('.rm_f2').text('C');
					$('.rm_f3').text('D');
					$('.rm_f4').text('E♭');
					$('.rm_f5').text('F');
					$('.rm_f6').text('G');
					$('.rm_f7').text('A');
					$('.rm_s1').text('B#');
					$('.rm_s2').text('D');
					$('.rm_s3').text('E');
					$('.rm_s4').text('E#');
					$('.rm_s5').text('G');
					$('.rm_s6').text('A');
					$('.rm_s7').text('B');
					break;

			   case 'F#':
					$('.rm_1').text('F#');
					$('.rm_2').text('G#');
					$('.rm_3').text('A#');
					$('.rm_4').text('B');
					$('.rm_5').text('C#');
					$('.rm_6').text('D#');
					$('.rm_7').text('E#');
					$('.rm_f1').text('F');
					$('.rm_f2').text('G');
					$('.rm_f3').text('A');
					$('.rm_f4').text('B♭');
					$('.rm_f5').text('C');
					$('.rm_f6').text('D');
					$('.rm_f7').text('E');
					$('.rm_s1').text('G');
					$('.rm_s2').text('A');
					$('.rm_s3').text('B');
					$('.rm_s4').text('B#');
					$('.rm_s5').text('D');
					$('.rm_s6').text('E');
					$('.rm_s7').text('F#');
					break;

			   case 'C#':
					$('.rm_1').text('C#');
					$('.rm_2').text('D#');
					$('.rm_3').text('E#');
					$('.rm_4').text('F#');
					$('.rm_5').text('G#');
					$('.rm_6').text('A#');
					$('.rm_7').text('B#');
					$('.rm_f1').text('C');
					$('.rm_f2').text('D');
					$('.rm_f3').text('E');
					$('.rm_f4').text('F');
					$('.rm_f5').text('G');
					$('.rm_f6').text('A');
					$('.rm_f7').text('B');
					$('.rm_s1').text('D');
					$('.rm_s2').text('E');
					$('.rm_s3').text('F#');
					$('.rm_s4').text('G');
					$('.rm_s5').text('A');
					$('.rm_s6').text('B');
					$('.rm_s7').text('C#');
					break;

			   case 'F':
					$('.rm_1').text('F');
					$('.rm_2').text('G');
					$('.rm_3').text('A');
					$('.rm_4').text('B♭');
					$('.rm_5').text('C');
					$('.rm_6').text('D');
					$('.rm_7').text('E');
					$('.rm_f1').text('F♭');
					$('.rm_f2').text('G♭');
					$('.rm_f3').text('A♭');
					$('.rm_f4').text('A');
					$('.rm_f5').text('C♭');
					$('.rm_f6').text('D♭');
					$('.rm_f7').text('E♭');
					$('.rm_s1').text('F#');
					$('.rm_s2').text('G#');
					$('.rm_s3').text('A#');
					$('.rm_s4').text('B');
					$('.rm_s5').text('C#');
					$('.rm_s6').text('D#');
					$('.rm_s7').text('E#');
					break;

			   case 'B♭':
					$('.rm_1').text('B♭');
					$('.rm_2').text('C');
					$('.rm_3').text('D');
					$('.rm_4').text('E♭');
					$('.rm_5').text('F');
					$('.rm_6').text('G');
					$('.rm_7').text('A');
					$('.rm_f1').text('A');
					$('.rm_f2').text('C♭');
					$('.rm_f3').text('D♭');
					$('.rm_f4').text('D');
					$('.rm_f5').text('F♭');
					$('.rm_f6').text('G♭');
					$('.rm_f7').text('A♭');
					$('.rm_s1').text('B');
					$('.rm_s2').text('C#');
					$('.rm_s3').text('D#');
					$('.rm_s4').text('E');
					$('.rm_s5').text('F#');
					$('.rm_s6').text('G#');
					$('.rm_s7').text('A#');
					break;

			   case 'E♭':
					$('.rm_1').text('E♭');
					$('.rm_2').text('F');
					$('.rm_3').text('G');
					$('.rm_4').text('A♭');
					$('.rm_5').text('B♭');
					$('.rm_6').text('C');
					$('.rm_7').text('D');
					$('.rm_f1').text('D');
					$('.rm_f2').text('F♭');
					$('.rm_f3').text('G♭');
					$('.rm_f4').text('G');
					$('.rm_f5').text('A');
					$('.rm_f6').text('C♭');
					$('.rm_f7').text('D♭');
					$('.rm_s1').text('E');
					$('.rm_s2').text('F#');
					$('.rm_s3').text('G#');
					$('.rm_s4').text('A');
					$('.rm_s5').text('B');
					$('.rm_s6').text('C#');
					$('.rm_s7').text('D#');
					break;

			   case 'A♭':
					$('.rm_1').text('A♭');
					$('.rm_2').text('B♭');
					$('.rm_3').text('C');
					$('.rm_4').text('D♭');
					$('.rm_5').text('E♭');
					$('.rm_6').text('F');
					$('.rm_7').text('G');
					$('.rm_f1').text('G');
					$('.rm_f2').text('A');
					$('.rm_f3').text('C♭');
					$('.rm_f4').text('C');
					$('.rm_f5').text('D');
					$('.rm_f6').text('F♭');
					$('.rm_f7').text('G♭');
					$('.rm_s1').text('A');
					$('.rm_s2').text('B');
					$('.rm_s3').text('C#');
					$('.rm_s4').text('D');
					$('.rm_s5').text('E');
					$('.rm_s6').text('F#');
					$('.rm_s7').text('G#');
					break;

			   case 'D♭':
					$('.rm_1').text('D♭');
					$('.rm_2').text('E♭');
					$('.rm_3').text('F');
					$('.rm_4').text('G♭');
					$('.rm_5').text('A♭');
					$('.rm_6').text('B♭');
					$('.rm_7').text('C');
					$('.rm_f1').text('C');
					$('.rm_f2').text('D');
					$('.rm_f3').text('F♭');
					$('.rm_f4').text('F');
					$('.rm_f5').text('G');
					$('.rm_f6').text('A');
					$('.rm_f7').text('C♭');
					$('.rm_s1').text('D');
					$('.rm_s2').text('E');
					$('.rm_s3').text('F#');
					$('.rm_s4').text('G');
					$('.rm_s5').text('A');
					$('.rm_s6').text('B');
					$('.rm_s7').text('C#');
					break;

			   case 'G♭':
					$('.rm_1').text('G♭');
					$('.rm_2').text('A♭');
					$('.rm_3').text('B♭');
					$('.rm_4').text('C♭');
					$('.rm_5').text('D♭');
					$('.rm_6').text('E♭');
					$('.rm_7').text('F');
					$('.rm_f1').text('F');
					$('.rm_f2').text('G');
					$('.rm_f3').text('A');
					$('.rm_f4').text('B♭');
					$('.rm_f5').text('C');
					$('.rm_f6').text('D');
					$('.rm_f7').text('F♭');
					$('.rm_s1').text('G');
					$('.rm_s2').text('A');
					$('.rm_s3').text('B');
					$('.rm_s4').text('C');
					$('.rm_s5').text('D');
					$('.rm_s6').text('E');
					$('.rm_s7').text('F#');
					break;

			  case 'C♭':
					$('.rm_1').text('C♭');
					$('.rm_2').text('D♭');
					$('.rm_3').text('E♭');
					$('.rm_4').text('F♭');
					$('.rm_5').text('G♭');
					$('.rm_6').text('A♭');
					$('.rm_7').text('B♭');
					$('.rm_f1').text('B♭');
					$('.rm_f2').text('C');
					$('.rm_f3').text('D');
					$('.rm_f4').text('E♭');
					$('.rm_f5').text('F');
					$('.rm_f6').text('G');
					$('.rm_f7').text('A');
					$('.rm_s1').text('C');
					$('.rm_s2').text('D');
					$('.rm_s3').text('E');
					$('.rm_s4').text('F');
					$('.rm_s5').text('G');
					$('.rm_s6').text('A');
					$('.rm_s7').text('B');
					break;
			}
		}

		// ************ マイナーを選択した場合 ************
		if ( val_tonality === 'minor' ) {
			$('#majorSection').hide().removeClass('show_effect'); // メジャーを非表示
			$('#minorSection').show().removeClass('show_effect').addClass('show_effect'); // マイナーを表示

			$('#key_1').attr('href','#minor_1');
			$('#key_2').attr('href','#minor_2');
			$('#key_3').attr('href','#minor_3');
			$('#key_4').attr('href','#minor_4');
			$('#key_5').attr('href','#minor_5');
			$('#key_6').attr('href','#minor_6');
			$('#key_7').attr('href','#minor_7');

			// 選択したキーによってスイッチ
			switch (val_chord){

				case 'Degree':
					defalutNum(); //初期化
					break;

				case 'A':
					$('.rm_1').text('A');
					$('.rm_2').text('B');
					$('.rm_3').text('C');
					$('.rm_4').text('D');
					$('.rm_5').text('E');
					$('.rm_6').text('F');
					$('.rm_7').text('G');
					$('.rm_f1').text('A♭');
					$('.rm_f2').text('B♭');
					$('.rm_f3').text('C♭');
					$('.rm_f4').text('D♭');
					$('.rm_f5').text('E♭');
					$('.rm_f6').text('F♭');
					$('.rm_f7').text('G♭');
					$('.rm_s1').text('A#');
					$('.rm_s2').text('B#');
					$('.rm_s3').text('C#');
					$('.rm_s4').text('D#');
					$('.rm_s5').text('E#');
					$('.rm_s6').text('F#');
					$('.rm_s7').text('G#');
					break;

			   case 'E':
					$('.rm_1').text('E');
					$('.rm_2').text('F#');
					$('.rm_3').text('G');
					$('.rm_4').text('A');
					$('.rm_5').text('B');
					$('.rm_6').text('C');
					$('.rm_7').text('D');
					$('.rm_f1').text('E♭');
					$('.rm_f2').text('F');
					$('.rm_f3').text('G♭');
					$('.rm_f4').text('A♭');
					$('.rm_f5').text('B♭');
					$('.rm_f6').text('C♭');
					$('.rm_f7').text('D♭');
					$('.rm_s1').text('E#');
					$('.rm_s2').text('G');
					$('.rm_s3').text('G#');
					$('.rm_s4').text('A#');
					$('.rm_s5').text('B#');
					$('.rm_s6').text('C#');
					$('.rm_s7').text('D#');
					break;

			   case 'B':
					$('.rm_1').text('B');
					$('.rm_2').text('C#');
					$('.rm_3').text('D');
					$('.rm_4').text('E');
					$('.rm_5').text('F#');
					$('.rm_6').text('G');
					$('.rm_7').text('A');
					$('.rm_f1').text('B♭');
					$('.rm_f2').text('C');
					$('.rm_f3').text('D♭');
					$('.rm_f4').text('E♭');
					$('.rm_f5').text('F');
					$('.rm_f6').text('G♭');
					$('.rm_f7').text('A♭');
					$('.rm_s1').text('B#');
					$('.rm_s2').text('D');
					$('.rm_s3').text('D#');
					$('.rm_s4').text('E#');
					$('.rm_s5').text('G');
					$('.rm_s6').text('G#');
					$('.rm_s7').text('A#');
					break;

			   case 'F#':
					$('.rm_1').text('F#');
					$('.rm_2').text('G#');
					$('.rm_3').text('A');
					$('.rm_4').text('B');
					$('.rm_5').text('C#');
					$('.rm_6').text('D');
					$('.rm_7').text('E');
					$('.rm_f1').text('F');
					$('.rm_f2').text('G');
					$('.rm_f3').text('A♭');
					$('.rm_f4').text('B♭');
					$('.rm_f5').text('C');
					$('.rm_f6').text('D♭');
					$('.rm_f7').text('E♭');
					$('.rm_s1').text('G');
					$('.rm_s2').text('A');
					$('.rm_s3').text('A#');
					$('.rm_s4').text('B#');
					$('.rm_s5').text('D');
					$('.rm_s6').text('D#');
					$('.rm_s7').text('E#');
					break;

			   case 'C#':
					$('.rm_1').text('C#');
					$('.rm_2').text('D#');
					$('.rm_3').text('E');
					$('.rm_4').text('F#');
					$('.rm_5').text('G#');
					$('.rm_6').text('A');
					$('.rm_7').text('B');
					$('.rm_f1').text('C');
					$('.rm_f2').text('D');
					$('.rm_f3').text('E♭');
					$('.rm_f4').text('F');
					$('.rm_f5').text('G');
					$('.rm_f6').text('A♭');
					$('.rm_f7').text('B♭');
					$('.rm_s1').text('D');
					$('.rm_s2').text('E');
					$('.rm_s3').text('E#');
					$('.rm_s4').text('G');
					$('.rm_s5').text('A');
					$('.rm_s6').text('A#');
					$('.rm_s7').text('B#');
					break;

			   case 'G#':
					$('.rm_1').text('G#');
					$('.rm_2').text('A#');
					$('.rm_3').text('B');
					$('.rm_4').text('C#');
					$('.rm_5').text('D#');
					$('.rm_6').text('E');
					$('.rm_7').text('F#');
					$('.rm_f1').text('G');
					$('.rm_f2').text('A');
					$('.rm_f3').text('B♭');
					$('.rm_f4').text('C');
					$('.rm_f5').text('D');
					$('.rm_f6').text('E♭');
					$('.rm_f7').text('F');
					$('.rm_s1').text('A');
					$('.rm_s2').text('B');
					$('.rm_s3').text('B#');
					$('.rm_s4').text('D');
					$('.rm_s5').text('E');
					$('.rm_s6').text('E#');
					$('.rm_s7').text('G');
					break;

			   case 'D#':
					$('.rm_1').text('D#');
					$('.rm_2').text('E#');
					$('.rm_3').text('F#');
					$('.rm_4').text('G#');
					$('.rm_5').text('A#');
					$('.rm_6').text('B');
					$('.rm_7').text('C#');
					$('.rm_f1').text('D');
					$('.rm_f2').text('E');
					$('.rm_f3').text('F');
					$('.rm_f4').text('G');
					$('.rm_f5').text('A');
					$('.rm_f6').text('B♭');
					$('.rm_f7').text('C');
					$('.rm_s1').text('E');
					$('.rm_s2').text('F#');
					$('.rm_s3').text('G');
					$('.rm_s4').text('A');
					$('.rm_s5').text('B');
					$('.rm_s6').text('B#');
					$('.rm_s7').text('D');
					break;

			   case 'A#':
					$('.rm_1').text('A#');
					$('.rm_2').text('B#');
					$('.rm_3').text('C#');
					$('.rm_4').text('D#');
					$('.rm_5').text('E#');
					$('.rm_6').text('F#');
					$('.rm_7').text('G#');
					$('.rm_f1').text('A');
					$('.rm_f2').text('B');
					$('.rm_f3').text('C');
					$('.rm_f4').text('D');
					$('.rm_f5').text('E');
					$('.rm_f6').text('F');
					$('.rm_f7').text('G');
					$('.rm_s1').text('B');
					$('.rm_s2').text('C#');
					$('.rm_s3').text('D');
					$('.rm_s4').text('E');
					$('.rm_s5').text('F#');
					$('.rm_s6').text('G');
					$('.rm_s7').text('A');
					break;

			   case 'D':
					$('.rm_1').text('D');
					$('.rm_2').text('E');
					$('.rm_3').text('F');
					$('.rm_4').text('G');
					$('.rm_5').text('A');
					$('.rm_6').text('B♭');
					$('.rm_7').text('C');
					$('.rm_f1').text('D♭');
					$('.rm_f2').text('E♭');
					$('.rm_f3').text('F♭');
					$('.rm_f4').text('G♭');
					$('.rm_f5').text('A♭');
					$('.rm_f6').text('A');
					$('.rm_f7').text('C♭');
					$('.rm_s1').text('D#');
					$('.rm_s2').text('E#');
					$('.rm_s3').text('F#');
					$('.rm_s4').text('G#');
					$('.rm_s5').text('A#');
					$('.rm_s6').text('B');
					$('.rm_s7').text('C#');
					break;

			   case 'G':
					$('.rm_1').text('G');
					$('.rm_2').text('A');
					$('.rm_3').text('B♭');
					$('.rm_4').text('C');
					$('.rm_5').text('D');
					$('.rm_6').text('E♭');
					$('.rm_7').text('F');
					$('.rm_f1').text('G♭');
					$('.rm_f2').text('A♭');
					$('.rm_f3').text('A');
					$('.rm_f4').text('C♭');
					$('.rm_f5').text('D♭');
					$('.rm_f6').text('D');
					$('.rm_f7').text('F♭');
					$('.rm_s1').text('G#');
					$('.rm_s2').text('A#');
					$('.rm_s3').text('B');
					$('.rm_s4').text('C#');
					$('.rm_s5').text('D#');
					$('.rm_s6').text('E');
					$('.rm_s7').text('F#');
					break;

			   case 'C':
					$('.rm_1').text('C');
					$('.rm_2').text('D');
					$('.rm_3').text('E♭');
					$('.rm_4').text('F');
					$('.rm_5').text('G');
					$('.rm_6').text('A♭');
					$('.rm_7').text('B♭');
					$('.rm_f1').text('C♭');
					$('.rm_f2').text('D♭');
					$('.rm_f3').text('D');
					$('.rm_f4').text('F♭');
					$('.rm_f5').text('G♭');
					$('.rm_f6').text('G');
					$('.rm_f7').text('A');
					$('.rm_s1').text('C#');
					$('.rm_s2').text('D#');
					$('.rm_s3').text('E');
					$('.rm_s4').text('F#');
					$('.rm_s5').text('G#');
					$('.rm_s6').text('A');
					$('.rm_s7').text('B');
					break;

			   case 'F':
					$('.rm_1').text('F');
					$('.rm_2').text('G');
					$('.rm_3').text('A♭');
					$('.rm_4').text('B♭');
					$('.rm_5').text('C');
					$('.rm_6').text('D♭');
					$('.rm_7').text('E♭');
					$('.rm_f1').text('F♭');
					$('.rm_f2').text('G♭');
					$('.rm_f3').text('G');
					$('.rm_f4').text('A');
					$('.rm_f5').text('C♭');
					$('.rm_f6').text('C');
					$('.rm_f7').text('D');
					$('.rm_s1').text('F#');
					$('.rm_s2').text('G#');
					$('.rm_s3').text('A');
					$('.rm_s4').text('B');
					$('.rm_s5').text('C#');
					$('.rm_s6').text('D');
					$('.rm_s7').text('E');
					break;

			   case 'B♭':
					$('.rm_1').text('B♭');
					$('.rm_2').text('C');
					$('.rm_3').text('D♭');
					$('.rm_4').text('E♭');
					$('.rm_5').text('F');
					$('.rm_6').text('G♭');
					$('.rm_7').text('A♭');
					$('.rm_f1').text('A');
					$('.rm_f2').text('C♭');
					$('.rm_f3').text('C');
					$('.rm_f4').text('D');
					$('.rm_f5').text('F♭');
					$('.rm_f6').text('F');
					$('.rm_f7').text('G');
					$('.rm_s1').text('B');
					$('.rm_s2').text('C#');
					$('.rm_s3').text('D');
					$('.rm_s4').text('E');
					$('.rm_s5').text('F#');
					$('.rm_s6').text('G');
					$('.rm_s7').text('A');
					break;

			   case 'E♭':
					$('.rm_1').text('E♭');
					$('.rm_2').text('F');
					$('.rm_3').text('G♭');
					$('.rm_4').text('A♭');
					$('.rm_5').text('B♭');
					$('.rm_6').text('C♭');
					$('.rm_7').text('D♭');
					$('.rm_f1').text('D');
					$('.rm_f2').text('F♭');
					$('.rm_f3').text('F');
					$('.rm_f4').text('G');
					$('.rm_f5').text('A');
					$('.rm_f6').text('B♭');
					$('.rm_f7').text('C');
					$('.rm_s1').text('E');
					$('.rm_s2').text('F#');
					$('.rm_s3').text('G');
					$('.rm_s4').text('A');
					$('.rm_s5').text('B');
					$('.rm_s6').text('C');
					$('.rm_s7').text('D');
					break;

			   case 'A♭':
					$('.rm_1').text('A♭');
					$('.rm_2').text('B♭');
					$('.rm_3').text('C♭');
					$('.rm_4').text('D♭');
					$('.rm_5').text('E♭');
					$('.rm_6').text('F♭');
					$('.rm_7').text('G♭');
					$('.rm_f1').text('G');
					$('.rm_f2').text('A');
					$('.rm_f3').text('B♭');
					$('.rm_f4').text('C');
					$('.rm_f5').text('D');
					$('.rm_f6').text('E♭');
					$('.rm_f7').text('F');
					$('.rm_s1').text('A');
					$('.rm_s2').text('B');
					$('.rm_s3').text('C');
					$('.rm_s4').text('D');
					$('.rm_s5').text('E');
					$('.rm_s6').text('F');
					$('.rm_s7').text('G');
					break;
			}
		}

		return false; // submitを無効
	});



});
